import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { SessionStorage } from "@/store/session-storage";
import { SearchItem, SearchResponse } from "@/api/shop/response";
import { SearchRequest } from "@/api/shop/request";
import * as ShopAPI from "@/api/shop";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "shop/search";

export class ListItem {
  constructor(private _item: SearchItem) {}
  get id() {
    return this._item.id;
  }
  get name() {
    return this._item.name;
  }
  get viewType() {
    return this._item.viewType == 1 ? "表示" : "非表示";
  }
  get agreementsType() {
    return this._item.agreementsType;
  }
  get writeFlg() {
    return this._item.writeFlg;
  }
  get deleteFlg() {
    return this._item.deleteFlg;
  }
}

/**
 * 【Admin】店舗一覧・検索API（/search-shop）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Search extends VuexModule {
  // state
  searchRequest: SearchRequest = {} as SearchRequest;
  searchResponse: SearchResponse = {} as SearchResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getWriteFlg() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.writeFlg;
    } else {
      return false;
    }
  }

  get getItems() {
    if (this.searchResponse.results) {
      return (this.searchResponse.results.items || []).map(
        value => new ListItem(value)
      );
    } else {
      return [] as ListItem[];
    }
  }

  get getTotalCount() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getSearchRequest() {
    return this.searchRequest;
  }

  // MutationActions
  @MutationAction
  async search(searchRequest: SearchRequest) {
    const searchResponse = await ShopAPI.search(searchRequest);
    SessionStorage.setObject(MODULE_NAME, searchRequest);
    return {
      searchRequest,
      searchResponse
    };
  }

  @MutationAction
  async restore(initial = {} as SearchRequest) {
    const searchRequest = SessionStorage.getObject(
      MODULE_NAME,
      initial
    ) as SearchRequest;
    return {
      searchRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as SearchResponse
    };
  }
}

export default getModule(Search);
